'use client';

import type { CmsPage } from '@petplate/cms/src/payload-types';
import Button from '@petplate/ui/components/Button';
import CarouselNavigation from '@petplate/ui/components/CarouselNavigation';
import Ellipsis from '@petplate/ui/components/EllipsisLoadingIndicator';
import ReviewCard from '@petplate/ui/components/ReviewCard';
import RichText from '@petplate/ui/components/RichText';
import useCarousel from '@petplate/ui/hooks/useCarousel';
import { gridMargins } from '@petplate/ui/theme/mixins/block';
import {
  ProductType,
  REVIEW_TYPE_COMPANY,
  REVIEW_TYPE_PRODUCT,
  REVIEW_TYPE_SUPPLEMENTS,
  REVIEW_TYPE_TREATS,
  ReviewType,
  buildReviewUrl,
  mapYotpoReviews
} from '@petplate/utils/yotpo';
import lens from '@refract-ui/sc/lens';
import { captureException } from '@sentry/nextjs';
import uniqBy from 'lodash/uniqBy';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { css } from 'styled-components';
import BlockWrapper from '../../components/BlockWrapper';
import Modal from '../../components/Modal';
import type { Type } from './types';

type WrapperProps = {
  $bgColor: Type['backgroundColor'];
};

type MappedButtonType = {
  button?: {
    label?: string;
    link?: {
      url?: string;
      linkType?: 'page' | 'absoluteUrl';
      absoluteUrl?: string;
      page?: string | CmsPage;
    };
    variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  };
  id?: string;
};

const CustomWrapper = lens()(BlockWrapper)<WrapperProps>`
  background-color: ${({ theme }) => theme.colorTokens.lightBlueBg};

  ${({ theme, $bgColor }) => {
    switch ($bgColor) {
      case 'blue':
        return css`
          background-color: ${theme?.colorTokens?.lightBlueBg};
          padding-bottom: ${theme?.spacing?.[11]}rem;
          padding-top: ${theme?.spacing?.[11]}rem;
        `;
      case 'neutral':
        return css`
          background-color: ${theme?.colorShades?.neutral20};
          padding-bottom: ${theme?.spacing?.[11]}rem;
          padding-top: ${theme?.spacing?.[11]}rem;
        `;
      default:
        return css`
          background-color: ${theme?.allColors?.white};
        `;
    }
  }}

  > div {
    grid-template-columns: unset;
  }
`;

const scrollBarOverrideCss = css`
  * {
    box-sizing: border-box;
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  * {
    -ms-overflow-style: none;
  }
`;

const calculatedMaxWidth = () => css`
  ${({ theme: { mq } }) => css`
    ${mq.xxs`
      max-width: calc(100vw - ${gridMargins.xxs * 2}rem);
    `}

    ${mq.sm`
      max-width: calc(100vw - ${gridMargins.sm * 2}rem);
    `}

    ${mq.md`
      max-width: calc(100vw - ${gridMargins.md * 2}rem);
    `}

    ${mq.lg`
      max-width: calc(100vw - ${gridMargins.lg * 2}rem);
    `}

    ${mq.xl`
      max-width: min(1400px, calc(100vw - ${gridMargins.xl * 2}rem));
    `}
  `}
`;

const BottomButton = lens()(Button)`
  margin-left: auto;
`;

const BottomContainer = lens.div()`
  ${({ theme: { mq } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: space-between;
    margin-top: 3rem;
    ${calculatedMaxWidth()}
    width: 100%;

    ${mq.sm`
      flex-direction: row;
    `}
  `}
`;

const Title = lens()(RichText)`
  ${({ theme: { mq } }) => css`
    text-align: center;

    ${mq.md`
      text-align: left;
    `}
  `}
`;

const Subtitle = lens()(RichText)`
  * {
    ${({ theme: { mq, box } }) => css`
      ${box.t('bodyMd')}
      text-align: center;

      ${mq.md`
        text-align: left;
      `}
    `}
  }
`;

const LoadingIndicator = lens()(Ellipsis)`
  align-items: center;
  display: flex;
  height: 16rem;
  justify-content: center;
`;

const OuterContainer = lens.div({ pv: 10 })`
  ${scrollBarOverrideCss}
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const TopContainer = lens.div()`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  margin-bottom: 2.5rem;
  ${calculatedMaxWidth()}
`;

const ScrollDiv = lens.div()`
  ${({ theme: { mq } }) => css`
    overflow: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    width: 100vw;
    margin: 0 -${gridMargins.xxs}rem;
    padding: 0 ${gridMargins.xxs}rem;

    ${mq.sm`
      margin: 0 -${gridMargins.sm}rem;
      padding: 0 ${gridMargins.sm}rem;
    `}

    ${mq.md`
      margin: 0 -${gridMargins.md}rem;
      padding: 0 ${gridMargins.md}rem;
    `}

    ${mq.lg`
      margin: 0 -${gridMargins.lg}rem;
      padding: 0 ${gridMargins.lg}rem;
    `}

    ${mq.xl`
      margin: 0 -${gridMargins.xl}rem;
      padding: 0 ${gridMargins.xl}rem;
    `}
  `}
`;

const StyledReviewCard = lens()(ReviewCard)`
  width: 300px !important;

  ${({ theme: { box, mq, allColors } }) => css`
    background-color: ${allColors?.white};
    ${box.sh('elevation1')};
    height: 16rem;
    // Adjust card width so next card in carousel
    // peeks out just a bit on mobile
    width: calc(90vw - 56px);

    ${mq.sm`
      width: 25.8125rem;
    `}
  `}
`;

const ModalReviewCard = lens()(ReviewCard)`
  ${({ theme: { box, mq } }) => css`
    ${box.sh('elevation1')};
    ${mq.sm`
      width: 25.8125rem;
    `}
  `}
`;

const TitleWrapper = lens.div()`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const UlCarousel = lens.div()`
  display: flex;
  gap: 2.5rem;
  list-style: none;
  margin: auto auto auto 0;
  // padding-left: ${gridMargins?.xxs}rem;
  // padding-right: ${gridMargins?.xxs}rem;
  width: -moz-fit-content;
  width: fit-content;

  // ${({ theme: { mq } }) => mq.sm`
  //   padding-left: ${gridMargins?.sm}rem;
  //   padding-right: ${gridMargins?.sm}rem;
  // `}

  // ${({ theme: { mq } }) => mq.xl`
  //   padding-left: ${gridMargins?.xl}rem;
  //   padding-right: ${gridMargins?.xl}rem;
  // `}
`;

// NOTE: Unused for the moment
function filterYotpoReviewsByProductSkus(
  reviews: ReviewType[],
  products: ProductType[],
  skus: string[]
): ReviewType[] {
  const treatProductIds = products
    .filter((product) => skus.includes(product.sku || ''))
    .map((product) => product.id);

  return reviews.filter((review) => treatProductIds.includes(review.productId ?? 0));
}

const titleFallback = [
  {
    children: [
      {
        text: 'Customer '
      },
      {
        text: 'reviews',
        keyword: '#0055B8'
      }
    ],
    type: 'h2'
  }
];

type AddedTypes = {
  className?: string;
};

const YotpoReviewsCarousel: React.FC<Type & AddedTypes> = ({
  backgroundColor,
  blockSettings,
  buttons = [],
  buttonPosition,
  className,
  reviewType = REVIEW_TYPE_COMPANY,
  showArrows = true,
  sku,
  subtitle,
  title = titleFallback
}) => {
  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [modalStates, setModalStates] = useState<{ [k: number]: boolean }>({});

  const setToShowModal = (cardId: number) => {
    setModalStates((prevStates) => ({ ...prevStates, [cardId]: true }));
  };

  const setToCloseModal = (cardId: number) => {
    setModalStates((prevStates) => {
      const { [cardId]: _, ...rest } = prevStates;
      return rest;
    });
  };

  const modalTrigger = (cardId: number) => {
    modalStates[cardId] ? setToCloseModal(cardId) : setToShowModal(cardId);
  };

  const {
    carouselScrollerRef,
    slideNext,
    slidePrevious,
    handleScroll,
    isPreviousButtonEnabled,
    isNextButtonEnabled
  } = useCarousel({
    scrollAmount: 453
  });

  /**
   * Asynchronously fetches reviews for the given SKUs.
   *
   * @param skus - An array of SKUs for which reviews are to be fetched.
   * @returns A promise that resolves to an array of ReviewType objects.
   */
  async function fetchAllReviewsFor(skus: string[]): Promise<ReviewType[]> {
    const promises: Promise<ReviewType[]>[] = [];
    skus.forEach((sku) => {
      const url = buildReviewUrl(sku || '', { perPage: 10, page: 1 });
      const promise = fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const reviews = mapYotpoReviews(data?.response?.reviews);
          return reviews;
        });
      promises.push(promise);
    });

    const results = await Promise.all(promises);
    return uniqBy(results.flat(), 'id');
  }

  /**
   * Fetches Yotpo reviews from the specified Yotpo API URL, maps the response data to internal format,
   * and updates the component state with the reviews. Handles errors and loading state.
   *
   * @param yotpoApiUrl The URL to fetch Yotpo reviews from.
   */
  const fetchYotpoReviews = async (yotpoApiUrl: string) => {
    try {
      const response = await fetch(yotpoApiUrl);
      const data = await response.json();
      const mappedData = mapYotpoReviews(data?.response?.reviews);
      setReviews(mappedData);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetches multiple product reviews based on the provided review type.
   * If the review type is treats, fetches reviews for specific treats products.
   * If the review type is supplements, fetches reviews for various supplement products.
   * Captures any errors that occur during the fetch process and sets isLoading state to false after completion.
   */
  async function fetchMultipleProductReviews(reviewType: string) {
    try {
      if (reviewType === REVIEW_TYPE_TREATS) {
        // Chicken Apple & Beef & Sweet Treats
        const treatsProductSkus = ['5345', '62274', '62274-E', '5345-E'];
        const reviews = await fetchAllReviewsFor(treatsProductSkus);
        setReviews(reviews);
      }
      if (reviewType === REVIEW_TYPE_SUPPLEMENTS) {
        // All supplements products
        const supplementProductSkus = [
          '62204-E',
          '62204-S',
          '62214-E',
          '62214-S',
          '62234-S',
          '62234-E',
          '62224-S',
          '62224-E'
        ];
        const reviews = await fetchAllReviewsFor(supplementProductSkus);
        setReviews(reviews);
      }
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const pagination = { perPage: 10, page: 1 };
    switch (reviewType) {
      case REVIEW_TYPE_PRODUCT:
        fetchYotpoReviews(buildReviewUrl(sku || '', pagination));
        break;
      case REVIEW_TYPE_COMPANY:
        fetchYotpoReviews(buildReviewUrl('yotpo_site_reviews', pagination));
        break;
      case REVIEW_TYPE_TREATS:
      case REVIEW_TYPE_SUPPLEMENTS:
        fetchMultipleProductReviews(reviewType);
        break;
      default:
        fetchYotpoReviews(buildReviewUrl('yotpo_site_reviews', pagination));
    }
  }, [isLoading, reviewType]);

  const isReviewsEmtpy = useMemo(() => reviews?.length <= 0, [reviews]);

  // Don't render reviews block if none are returned from Yotpo:
  if (!isLoading && isReviewsEmtpy) {
    return <></>;
  }

  return (
    <CustomWrapper
      anchorId={blockSettings?.anchorId}
      maxWidth={blockSettings?.maxWidth || 'none'}
      responsiveStyles={blockSettings?.responsiveStyles}
      $bgColor={backgroundColor}
      showOverflow
    >
      <OuterContainer className={className}>
        <TopContainer>
          <TitleWrapper>
            <Title content={title} />
            {subtitle && <Subtitle content={subtitle} />}
          </TitleWrapper>
          {buttonPosition === 'topLeft' &&
            buttons?.length > 0 &&
            buttons?.map((b: MappedButtonType) => <Button key={b?.id} {...b?.button} />)}
        </TopContainer>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <ScrollDiv ref={carouselScrollerRef} onScroll={handleScroll}>
            <UlCarousel>
              {reviews?.map((review) => {
                return (
                  <Fragment key={review?.id}>
                    <StyledReviewCard
                      rating={review.score}
                      customerName={review.name}
                      review={review.content}
                      key={review?.id}
                      onClick={(_) => modalTrigger(review?.id as number)}
                    />
                    <Modal
                      show={modalStates[review?.id as number]}
                      close={(_) => setToCloseModal(review?.id as number)}
                      large={true}
                      full={true}
                    >
                      <ModalReviewCard
                        rating={review.score}
                        customerName={review.name}
                        review={review.content}
                        key={review?.id}
                        clamp="none"
                        showReadMore={false}
                        onClick={(_) => modalTrigger(review?.id as number)}
                      />
                    </Modal>
                  </Fragment>
                );
              })}
            </UlCarousel>
          </ScrollDiv>
        )}
        <BottomContainer>
          {showArrows && (
            <CarouselNavigation
              isNextButtonEnabled={isNextButtonEnabled}
              isPreviousButtonEnabled={isPreviousButtonEnabled}
              slideNext={slideNext}
              slidePrevious={slidePrevious}
            />
          )}
          {buttonPosition === 'bottomRight' &&
            buttons?.length > 0 &&
            buttons?.map((b: MappedButtonType) => <BottomButton key={b?.id} {...b?.button} />)}
        </BottomContainer>
      </OuterContainer>
    </CustomWrapper>
  );
};

export default YotpoReviewsCarousel;
