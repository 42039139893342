import {
  containerCss,
  contentCss,
  extraTextPadding,
  gridMargins
} from '@petplate/ui/theme/mixins/block';
import lens from '@refract-ui/sc/lens';
import React from 'react';
import { css } from 'styled-components';
import { ResponsiveStyles } from '../../theme/utils/genResponsiveStyles';

type WrapperTypes = {
  $responsiveStyles?: ResponsiveStyles;
  $maxWidth?: '768px' | '1100px' | '1400px' | 'none';
  $noPadding?: boolean | string;
  $textBlock?: boolean | string;
};

const Wrapper = lens.section()<WrapperTypes>`
  ${({ theme: { mq }, $responsiveStyles, $maxWidth, $noPadding, $textBlock }) => css`
    ${containerCss($responsiveStyles)}
    padding: 0 ${$textBlock ? extraTextPadding.xxs + gridMargins.xxs : gridMargins.xxs}rem;
    ${mq.sm`
      padding: 0 ${$textBlock ? extraTextPadding.sm + gridMargins.sm : gridMargins.sm}rem;
    `}
    ${mq.md`
      padding: 0 ${$textBlock ? extraTextPadding.md + gridMargins.md : gridMargins.md}rem;
    `}
    ${mq.lg`
      padding: 0 ${$textBlock ? extraTextPadding.lg + gridMargins.lg : gridMargins.lg}rem;
    `}
    ${mq.xl`
      padding: 0 ${$textBlock ? extraTextPadding.xl + gridMargins.xl : gridMargins.xl}rem;
      `}
    ${$maxWidth === 'none' &&
    $noPadding &&
    css`
      && {
        padding: 0;
      }
    `}
  `}
`;

type BlockContentTypes = {
  $maxWidth?: string;
  $showOverflow?: boolean;
};

export const BlockContent = lens.div()<BlockContentTypes>`
  ${({ $maxWidth, $showOverflow }) => css`
    ${contentCss({ maxWidth: $maxWidth })}
    overflow: ${$showOverflow ? 'visible' : 'hidden'};
  `}
`;

export type BlockWrapper = {
  children: React.ReactNode;
  maxWidth?: '768px' | '1100px' | '1400px' | 'none';
  anchorId?: string;
  responsiveStyles?: ResponsiveStyles;
  noPadding?: boolean;
  textBlock?: boolean;
  showOverflow?: boolean;
};

const BlockWrapper: React.FC<BlockWrapper> = ({
  children,
  maxWidth,
  responsiveStyles,
  anchorId,
  noPadding = false,
  textBlock = false,
  showOverflow = false,
  ...props
}) => {
  return (
    <Wrapper
      id={anchorId}
      $responsiveStyles={responsiveStyles}
      $maxWidth={maxWidth}
      $noPadding={noPadding}
      $textBlock={textBlock}
      {...props}
    >
      <BlockContent className="inner" $maxWidth={maxWidth} $showOverflow={showOverflow}>
        <>{children}</>
      </BlockContent>
    </Wrapper>
  );
};

export default BlockWrapper;
