import { PrimaryText } from '@petplate/ui/components/PrimaryText';
import map from 'lodash/map';
import * as Components from '.';
import DogEatingFoodImgSrc from '../images/dog-eating-food.png';
import BoxesIcon from '../images/icon-boxes.svg';
import PlateIcon from '../images/icon-plate.svg';
import PremiumIcon from '../images/icon-premium.svg';
import VetIcon from '../images/icon-vet.svg';

const descriptionItems = [
  {
    Icon: PlateIcon,
    description: '100% Human-Grade Meals'
  },
  {
    Icon: VetIcon,
    description: 'Vet-formulated plans'
  },
  {
    Icon: BoxesIcon,
    description: 'Portioned meals for healthy weight'
  },
  {
    Icon: PremiumIcon,
    description: '100% Money Back Guarantee'
  }
];

export const WhyPetPlateInfo = () => (
  <Components.WhyPetPlate>
    <Components.WhyPetPlateTitle>
      Why <PrimaryText>PetPlate?</PrimaryText>
    </Components.WhyPetPlateTitle>
    <Components.WhyPetPlateImage src={DogEatingFoodImgSrc} alt="Dog eating food" />
    <Components.WhyPetPlateDesc>
      {map(descriptionItems, (item, idx) => (
        <Components.WhyPetPlateDescItem key={idx}>
          <item.Icon />
          <Components.WhyPetPlateDescText>{item.description}</Components.WhyPetPlateDescText>
        </Components.WhyPetPlateDescItem>
      ))}
    </Components.WhyPetPlateDesc>
  </Components.WhyPetPlate>
);
