import { useGoBackStep } from '@/lib/hooks/steps';
import { useShowPromo } from '@/lib/hooks/useShowPromo';
import { StepGroups } from '@/types/steps';
import SvgArrowLeft from '@petplate/icons/core/ArrowLeft';
import PetPlateXs from '@petplate/icons/logo/PetPlateXs';
import YotpoReviews from '@petplate/ui/blocks/YotpoReviewsCarousel';
import Button from '@petplate/ui/components/Button';
import { useSiteWideDiscount } from '@petplate/ui/components/DiscountProvider';
import IconTypes from '@petplate/ui/types/icon';
import lens from '@refract-ui/sc/lens';
import { css } from 'styled-components';

export const Container = lens.div()<{ $view?: StepGroups; $loadingScreen?: boolean }>`
  ${({ $loadingScreen }) => css`
    width: 100vw;
    height: ${$loadingScreen ? '100%' : 'unset'};
    min-height: ${$loadingScreen ? 'unset' : '100vh'};
    display: grid;
    justify-items: center;
    grid-template-columns: auto;
    grid-template-rows: min-content 1fr;

    &:has(${Banner}) {
      grid-template-rows: min-content min-content 1fr;
    }
  `}
`;

export const HeadWrapper = lens.div()`
  ${({ theme: { mq } }) => css`
    padding: 0 1.5rem;
    width: 100%;
    ${mq.md`
      padding: 0 3.75rem;
    `}
  `}
`;

export const HeadContainer = lens.div()`
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: auto;
`;

export const Top = lens.div()`
  padding: 1.5rem 0;
  display: grid;
  box-sizing: content-box;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'back logo placeholder';
`;

export const StyledLogo = lens()(PetPlateXs)`
  grid-area: logo;
  justify-self: center;
`;

export const MainIntro = lens.div()`
  ${({ theme: { mq } }) => css`
    justify-self: center;
    align-self: self-start;
    min-height: 100%;
    display: flex;
    justify-content: center;
    padding: 1.25rem 1.5rem 5.7rem;
    width: 100%;
    overflow: hidden;

    ${mq.sm`
      padding: 1.75rem 3.75rem 5.9rem;
    `}
  `}
`;

export const MainPet = lens.div()`
  justify-self: center;
  align-self: self-start;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ theme: { mq } }) => css`
    padding: 1.25rem 1.5rem 5.7rem;
    width: 100%;

    ${mq.sm`
      padding: 1.75rem 3.75rem 5.9rem;
      width: auto;
    `}
  `}
`;

export const MainProducts = lens.div()`
  justify-self: center;
  align-self: self-start;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${({ theme: { mq } }) => css`
    padding: 0 1.5rem;

    ${mq.md`
      padding: 1.75rem 3.75rem;
    `}
  `}
`;

export const MainOther = lens.div()`
  ${({ theme: { mq, colorShades } }) => css`
    background-color: ${colorShades.blue20};
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0 1.5rem 4rem;

    ${mq.md`
      padding: 0 3.75rem;
    `}
  `}
`;

export const ExtraInfoContainer = lens.div({ rg: 8 })`
  display: flex;
  flex-direction: column;
  padding-bottom: 135px;
  width: 100%;
`;

export const Footer = lens.div()`
  ${({ theme: { box, mq } }) => css`
    ${box.t('bodyLg')}
    ${box.bg('light')}
    display: flex;
    flex-direction: column;
    box-shadow: 0px -1px 6px 0px #00000026;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    gap: 1rem;
    z-index: 3;

    ${mq.md`
      display: flex;
      flex-direction:column;
      align-items: center;
      width: 100vw;
      padding: 1rem 1.5rem;
      `}
  `}
`;

export const FooterText = lens.div({
  t: ['bodyMd', { md: 'bodyLg' }],
  p: 0,
  m: 0
})`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = lens.div()`
  padding: 2rem 1.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const YotpoReviewsCarousel = lens()(YotpoReviews)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const TopConfirmation = lens.div()`
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
`;

const StyledButton = lens()(Button)`
  div {
    height: 1.5rem;
    justify-content: start;
  }
`;

const Banner = lens.div()`
  ${({ theme: { box } }) => css`
    text-align: center;
    padding: 0.5rem;
    ${box.bg('promo')}
    width: 100%;
  `}
`;

const StyledP = lens.p()`
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
`;

const StyledS = lens.span()`
  text-decoration: underline;
  font-weight: bold;
`;

export const PromoBanner = () => {
  const showPromo = useShowPromo();
  const { discountPercentCoupon, loading } = useSiteWideDiscount();
  const showBanner = showPromo && !loading;

  return (
    showBanner && (
      <Banner>
        {discountPercentCoupon?.isURLCoupon &&
        !discountPercentCoupon?.linkedChainPromoBannerText ? (
          <StyledP>
            Offer redeemed! {discountPercentCoupon?.percentOff}% will be automatically applied at
            checkout.
          </StyledP>
        ) : (
          <StyledP>
            {discountPercentCoupon?.linkedChainPromoBannerText ? (
              <>
                {discountPercentCoupon.linkedChainPromoBannerText}. <StyledS>REDEEM NOW</StyledS>
              </>
            ) : (
              <>
                Get {discountPercentCoupon?.percentOff}% off the 1st box, 20% off the 2nd box, and
                10% off the 3rd box. <StyledS>REDEEM NOW</StyledS>
              </>
            )}
          </StyledP>
        )}
      </Banner>
    )
  );
};

export const BackButton = () => {
  const goBack = useGoBackStep();

  return (
    <StyledButton
      variant="text"
      onClick={goBack}
      label="Back"
      color="light"
      leadingIcon={SvgArrowLeft as IconTypes}
    />
  );
};
