import { RecommendationWizardPlanTypeEnum as PlanTypes } from '@petplate/schema';
import lens from '@refract-ui/sc/lens';
import { css } from 'styled-components';

export const WhyPetPlate = lens.div({
  bg: 'yellow30',
  cg: 8,
  rg: [6, { md: 10, lg: 11 }],
  ph: [0, { md: 11, lg: 14 }]
})`
  ${({ theme: { mq } }) => css`
    display: flex;
    flex-direction: column;

    ${mq.md`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'title image'
        'description image';
    `}
  `}
`;

export const WhyPetPlateTitle = lens.h2({ mb: 0 })`
  grid-area: title;
  text-align: center;

  ${({ theme: { mq } }) => css`
    ${mq.md`
      text-align: left;
      margin-top: 3rem;
    `}

    ${mq.lg`
      margin-top: 5.3rem;
    `}
  `}
`;

export const WhyPetPlateDesc = lens.div({
  g: [6, { md: 10 }],
  ph: [6, { md: 0 }],
  pb: [8, { md: 10, lg: 12 }]
})`
  grid-area: description;
  display: flex;
  flex-direction: column;
`;

export const WhyPetPlateDescItem = lens.div({ g: [4, { md: 6 }] })`
  display: grid;
  align-items: start;
  grid-template-columns: 36px 1fr;
  min-height: 38px;
`;

export const WhyPetPlateDescText = lens.p({
  m: 0,
  mt: [1, { md: 0 }],
  p: 0,
  t: ['h5', { md: 'h4' }]
})`
  white-space: pre-wrap;
`;

export const WhyPetPlateImage = lens.img()`
  ${({ theme: { mq } }) => css`
    grid-area: image;
    width: 100%;
    height: 250px;
    object-fit: cover;

    ${mq.md`
      height: 100%;
    `}

    ${mq.xxl`
      max-height: 580px;
    `}
  `}
`;

export const OurProductsWrapper = lens.div({ rg: 11, cg: 6, mt: 4, pt: 11, pb: 8 })`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'baked'
    'combo'
    'cooked'
    'topper';
`;

export const OurProductsOverlayBg = lens.div({ bg: 'blue20' })`
  ${({ theme: { mq } }) => css`
    height: 700px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    ${mq.md`
      height: 750px;
    `}

    ${mq.lg`
      height: 675px;
      border-top-right-radius: 3rem;
    `}
  `}
`;

export const OurProductsTitle = lens.h2({ m: 0 })`
  ${({ theme: { mq, box } }) => css`
    grid-area: title;
    text-align: center;
    z-index: 1;

    &:before {
      content: 'Our ';
    }

    &:after {
      content: 'plans';
      ${box.c('primary300')};
    }

    ${mq.md`
      &:after {
        content: 'meal plans';
      }
    `}
  `}
`;

export const MealPlanWrapper = lens.div({
  rg: [4, { md: 6, lg: 0 }],
  cg: [0, { md: 8 }],
  ph: [0, { md: 8, lg: 12 }],
  pr: [0, { md: 6 }]
})<{ $planType: PlanTypes }>`
  ${({ theme: { mq, box } }) => css`
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'info';
    z-index: 1;

    &:has(#recommended-tag) {
      ${MealPlanTitleContainer} {
        ${box.mt(4)}
      }
    }

    &:last-child {
      &:has(#recommended-tag) {
        ${MealPlanTitleContainer} {
          ${box.mt(8)}
        }
      }
    }

    ${mq.lg`
      &:has(#recommended-tag) {
        ${MealPlanTitleContainer} {
          ${box.mt(0)}
        }
      }

      display: flex;
      justify-content: flex-start;

      &:nth-child(even) {
        padding-left: calc(50% - 210px);

        ${MealPlanInfo} {
          justify-content: flex-start;
          margin-top: 3rem;
        }
      }

      &:last-child {
        padding-left: calc(50% - 170px);

        ${MealPlanInfo} {
          margin-left: 1.5rem;
        }
      }
    `}
  `}
`;

export const MealPlanOverlay = lens.div({ bg: 'blue20' })`
  ${({ theme: { mq } }) => css`
    height: 108px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    ${mq.md`
      height: 160px;
    `}

    ${mq.lg`
      height: 203px;
      border-bottom-left-radius: 3rem;
    `}
  `}
`;

export const MealPlanInfo = lens.div({ rg: 2 })`
  ${({ theme: { mq } }) => css`
    grid-area: info;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 445px;
    z-index: 1;

    ${mq.md`
      max-width: 525px;
    `}

    ${mq.lg`
      max-width: 445px;
      margin: -2rem 0 0;
      justify-content: center;
    `}
  `}
`;

export const MealPlanImage = lens.img()<{ $smaller?: boolean }>`
  ${({ $smaller, theme: { mq } }) => css`
    grid-area: image;
    margin: 0 auto;
    width: ${$smaller ? '200px' : '220px'};
    z-index: 1;

    ${mq.md`
      width: ${$smaller ? '273px' : '322px'};
    `}

    ${mq.lg`
      margin: 0;
      width: ${$smaller ? '353px' : '412px'};
    `}
  `}
`;

export const ExtraImage = lens.img()`
  ${({ theme: { mq } }) => css`
    display: none;
    position: absolute;
    top: 69px;
    left: calc(50% - 450px);

    ${mq.lg`
      display: block;
    `}
  `}
`;

export const MealPlanTitleContainer = lens.div({ m: 0 })`
  ${({ theme: { mq } }) => css`
    text-align: center;
    position: relative;

    ${mq.lg`
      text-align: left;
    `}
  `}
`;

export const MealPlanTitle = lens.h4({ c: 'primary300', m: 0 })``;

export const MealPlanText = lens.span({ t: 'bodyLg', ph: [6, { md: 0 }] })`
  ${({ theme: { mq } }) => css`
    text-align: center;

    ${mq.lg`
      text-align: left;
    `}
  `}
`;

export const TagWrapper = lens.div()`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);

    svg {
      height: 24px;
      width: 100%;
    }

    ${mq.md`
      top: -42px;

      svg {
        height: 32px;
      }
    `}

    ${mq.lg`
      top: -57px;
      left: unset;
      transform: unset;

      svg {
        height: 42px;
      }
    `}
  `}
`;
