import { PlansLandingInfo } from '@/components/PlansLandingInfo';
import { WizardProgressBar } from '@/components/WizardProgressBar';
import { ProductRecsProvider } from '@/components/providers/ProductRecsProvider';
import { StepGroups, SubflowSteps } from '@/types/steps';
import {
  REVIEW_TYPE_COMPANY,
  REVIEW_TYPE_SUPPLEMENTS,
  REVIEW_TYPE_TREATS,
  ReviewCategoryType
} from '@petplate/utils/yotpo';
import some from 'lodash/some';
import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  BackButton,
  Container,
  ExtraInfoContainer,
  HeadContainer,
  HeadWrapper,
  MainIntro,
  MainOther,
  MainPet,
  MainProducts,
  PromoBanner,
  StyledLogo,
  Top,
  YotpoReviewsCarousel
} from '../components';

const stepsToShowYotpoReviews = [
  SubflowSteps.Plan,
  SubflowSteps.Plans,
  SubflowSteps.Recipes,
  SubflowSteps.Treats,
  SubflowSteps.Supplements
];

type StepLayoutProps = {
  view: StepGroups;
};

export const StepLayout: React.FC<StepLayoutProps> = ({ view }) => {
  const { pathname } = useLocation();

  const showBackButton = !pathname.includes(SubflowSteps.Intro);
  const showHeadContainer = !pathname.includes(SubflowSteps.CalculatingPlan);
  const showPlansLanding = pathname.includes(SubflowSteps.Plans);
  const showExtraContainer = some(stepsToShowYotpoReviews, (step) => pathname.includes(step));

  const reviewType: ReviewCategoryType = useMemo(() => {
    if (pathname.includes(SubflowSteps.Treats)) {
      return REVIEW_TYPE_TREATS;
    } else if (pathname.includes(SubflowSteps.Supplements)) {
      return REVIEW_TYPE_SUPPLEMENTS;
    } else {
      return REVIEW_TYPE_COMPANY;
    }
  }, [pathname]);

  const ViewWrapper = useMemo(() => {
    switch (view) {
      case StepGroups.Intro:
        return MainIntro;
      case StepGroups.Pet:
        return MainPet;
      case StepGroups.Products: {
        if (showHeadContainer) return MainProducts;
        return MainOther;
      }
      default:
        return React.Fragment;
    }
  }, [showHeadContainer, view]);

  const Providers = useMemo(() => {
    return view === StepGroups.Products ? ProductRecsProvider : React.Fragment;
  }, [view]);

  return (
    <Container $view={view} $loadingScreen={!showHeadContainer}>
      <PromoBanner />
      {showHeadContainer ? (
        <HeadWrapper>
          <HeadContainer>
            <Top>
              {showBackButton && <BackButton />}
              <StyledLogo />
            </Top>
            <WizardProgressBar />
          </HeadContainer>
        </HeadWrapper>
      ) : (
        <div />
      )}
      <Providers>
        <ViewWrapper>
          <Outlet />
        </ViewWrapper>
        {showExtraContainer && (
          <ExtraInfoContainer>
            {showPlansLanding && <PlansLandingInfo />}
            {showExtraContainer && !showPlansLanding && (
              <YotpoReviewsCarousel
                blockType="YotpoReviewsCarousel"
                backgroundColor="blue"
                textAlignment="center"
                reviewType={reviewType}
              />
            )}
          </ExtraInfoContainer>
        )}
      </Providers>
    </Container>
  );
};
