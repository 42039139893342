import { REVIEW_TYPE_COMPANY } from '@petplate/utils/yotpo';
import { YotpoReviewsCarousel } from '../layouts/components';
import { OurProductsInfo } from './components/OurProductsInfo';
import { WhyPetPlateInfo } from './components/WhyPetPlateInfo';

export const PlansLandingInfo = () => {
  return (
    <>
      <WhyPetPlateInfo />
      <YotpoReviewsCarousel
        blockType="YotpoReviewsCarousel"
        backgroundColor="white"
        textAlignment="left"
        reviewType={REVIEW_TYPE_COMPANY}
      />
      <OurProductsInfo />
    </>
  );
};
