export enum Flows {
  FreshBaked = 'fb-subscription',
  Regular = 'subscription'
}

export enum StepGroups {
  Intro = 'intro',
  Pet = 'my-pup',
  Products = 'products'
}

export enum SubflowSteps {
  Intro = '/intro',
  Name = '/name',
  // TODO: Temporary duplicate while we test a reorder on the email step
  ParentEmail = '/email-address',
  Gender = '/gender',
  Breed = '/breed',
  Age = '/age',
  Weight = '/weight',
  Activity = '/activity',
  Waistline = '/waistline',
  Sensitivities = '/food-sensitivities',
  Goals = '/wellness-goals',
  CalculatingPlan = '/calculating-plan',
  Plans = '/plans',
  Plan = '/plan',
  Recipes = '/recipes',
  Email = '/email',
  Treats = '/treats',
  Supplements = '/supplements',
  PlanConfirmation = '/plan-confirmation'
}

export type StepPath = keyof {
  [S in SubflowSteps as `/${Flows}${S}`]: true;
};

export type Step = {
  path: SubflowSteps;
  group: StepGroups;
  fullPath: StepPath;
};
