import { useProductRecs } from '@/lib/hooks/useProductRecs';
import { RecommendationWizardPlanTypeEnum as PlanTypes } from '@petplate/schema';
import { useMemo } from 'react';
import * as Components from '.';
import DogSniffingImg from '../images/dog-sniffing.png';
import FreshBakedBowlImg from '../images/fresh-baked-bowl.png';
import FreshComboBowlImg from '../images/fresh-combo-bowl.png';
import FreshCookedBowlImg from '../images/fresh-cooked-bowl.png';
import RecommendedTag from '../images/recommended-tag.svg';
import ToppersImg from '../images/toppers.png';

type MealPlan = {
  planType: PlanTypes;
  title: string;
  image: string;
  imageDesc: string;
  description: string;
  showExtraImg?: boolean;
  smallerImg?: boolean;
  showOverlay?: boolean;
  recommended?: boolean;
};

const MealPlanDesc: React.FC<MealPlan> = ({
  planType,
  title,
  image,
  imageDesc,
  description,
  showExtraImg,
  smallerImg,
  showOverlay,
  recommended
}) => (
  <Components.MealPlanWrapper $planType={planType}>
    {showOverlay && <Components.MealPlanOverlay />}
    <Components.MealPlanImage src={image} alt={imageDesc} $smaller={smallerImg} />
    {showExtraImg && <Components.ExtraImage src={DogSniffingImg} alt="dog sniffing food" />}
    <Components.MealPlanInfo>
      <Components.MealPlanTitleContainer>
        {recommended && (
          <Components.TagWrapper id="recommended-tag">
            <RecommendedTag />
          </Components.TagWrapper>
        )}
        <Components.MealPlanTitle>{title}</Components.MealPlanTitle>
      </Components.MealPlanTitleContainer>
      <Components.MealPlanText>{description}</Components.MealPlanText>
    </Components.MealPlanInfo>
  </Components.MealPlanWrapper>
);

export const OurProductsInfo = () => {
  const { recommendations, loading } = useProductRecs();

  const { freshBakedBox, fullBox, topperBox } = recommendations?.planRecommendations.boxes || {};

  const recommendedPlanType = useMemo(() => {
    if (loading) return undefined;
    else if (freshBakedBox?.boxVariant.recommended) return PlanTypes.Baked;
    else if (topperBox?.boxVariant.recommended) return PlanTypes.Topper;
    else if (fullBox?.boxVariant.recommended) return PlanTypes.Full;
    return PlanTypes.Combo;
  }, [recommendations?.planRecommendations]);

  return (
    <Components.OurProductsWrapper>
      <Components.OurProductsOverlayBg />
      <Components.OurProductsTitle />
      <MealPlanDesc
        planType={PlanTypes.Baked}
        title="FreshBaked"
        image={FreshBakedBowlImg}
        imageDesc="FreshBaked bowl"
        description="Perfect for pups who crave a crunch! Our most convenient, shelf-stable meal plan is ideal for the on-the-go pawrent. Slowly-baked to preserve nutrients, never “kibbled”."
        recommended={recommendedPlanType === PlanTypes.Baked}
      />
      <MealPlanDesc
        planType={PlanTypes.Combo}
        title="FreshCombo"
        image={FreshComboBowlImg}
        imageDesc="FreshCombo bowl"
        description="For pups who love variety! A mix of baked and cooked textures gives your pup the best of both worlds in every bite."
        recommended={recommendedPlanType === PlanTypes.Combo}
        showExtraImg
        showOverlay
      />
      <MealPlanDesc
        planType={PlanTypes.Full}
        title="FreshCooked"
        image={FreshCookedBowlImg}
        imageDesc="FreshCooked bowl"
        description="Our signature, gently-cooked plan, vet-formulated to provide vital nutrients. Perfect for picky eaters seeking premium taste, with six protein options to choose from."
        recommended={recommendedPlanType === PlanTypes.Full}
      />
      <MealPlanDesc
        planType={PlanTypes.Topper}
        title="Toppers"
        image={ToppersImg}
        imageDesc="Toppers"
        description="Our signature plan, offered in smaller portions. Perfect for supplementing prescription diets, or easing into feeding fresh. Covers ~25% of daily caloric needs."
        recommended={recommendedPlanType === PlanTypes.Topper}
        smallerImg
      />
    </Components.OurProductsWrapper>
  );
};
