import { OrderConfirmationLayout } from '@/components/layouts/OrderConfirmationLayout';
import { ResumeSubFlow } from '@/components/layouts/ResumeSubFlow';
import { StepLayout } from '@/components/layouts/StepLayout';
import { Providers } from '@/components/providers';
import {
  handleCheckoutConfirmation,
  loadOrderConfirmation
} from '@/lib/loaders/checkoutConfirmation';
import { handleResumeSubFlow } from '@/lib/loaders/resumeSubFlow';
import { freshBakedSteps, regularSteps } from '@/lib/utils/steps';
import { Flows, StepGroups, SubflowSteps, type Step } from '@/types/steps';
import { NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY, WEB_URL } from '@petplate/settings';
import ThemeProvider from '@petplate/ui/components/ThemeProvider';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  type RouteObject
} from 'react-router-dom';
import { SubscriptionFlow } from '../components/layouts/SubscriptionFlow';
import { ErrorBoundary } from './error';

const RouteComponents = {
  [SubflowSteps.Intro]: () => import('./subscription/(intro)/intro'),
  [SubflowSteps.Name]: () => import('./subscription/(my-pup)/name'),
  [SubflowSteps.ParentEmail]: () => import('./subscription/(parent)/email'),
  [SubflowSteps.Gender]: () => import('./subscription/(my-pup)/gender'),
  [SubflowSteps.Breed]: () => import('./subscription/(my-pup)/breed'),
  [SubflowSteps.Age]: () => import('./subscription/(my-pup)/age'),
  [SubflowSteps.Weight]: () => import('./subscription/(my-pup)/weight'),
  [SubflowSteps.Activity]: () => import('./subscription/(my-pup)/activity'),
  [SubflowSteps.Waistline]: () => import('./subscription/(my-pup)/waistline'),
  [SubflowSteps.Sensitivities]: () => import('./subscription/(my-pup)/food-sensitivities'),
  [SubflowSteps.Goals]: () => import('./subscription/(my-pup)/wellness-goals'),
  [SubflowSteps.CalculatingPlan]: () => import('./subscription/(other)/calculating-plan'),
  // This step has only 1 preselected plan
  [SubflowSteps.Plan]: () => import('./subscription/(products)/plan'),
  // This step shows all the plans to choose from
  [SubflowSteps.Plans]: () => import('./subscription/(products)/plans'),
  [SubflowSteps.Recipes]: () => import('./subscription/(products)/recipes'),
  [SubflowSteps.Email]: () => import('./subscription/(products)/email'),
  [SubflowSteps.Treats]: () => import('./subscription/(products)/treats'),
  [SubflowSteps.Supplements]: () => import('./subscription/(products)/supplements'),
  [SubflowSteps.PlanConfirmation]: () => import('./subscription/(products)/plan-confirmation')
};

// component to trigger the redirect to the web app root
const RedirectWebApp = () => {
  const { pathname } = useLocation();
  window.location.href = `${WEB_URL}${pathname}`;
  return null;
};

/**
 * Creates a route for a subflow step
 *
 * @param step the step to create a route for
 * @returns the route configuration for the given step, with a loader and a component
 */
const mapSubRoutes = (step: Step) =>
  ({
    path: step.fullPath,
    // lazy load each step's component
    lazy: RouteComponents[step.path]
  } as RouteObject);

/**
 * Creates all the available routes for a given flow
 *
 * @param flow the flow to create routes for
 * @param flowSteps the steps of the given flow
 * @returns the routes for each step of the given flow and wraps them with a StepsProvider to access from anywhere within
 */
const mapFlowRoutes = (flow: Flows, flowSteps: Step[]) => {
  const firstRoute = flowSteps[0].fullPath;
  return [
    {
      element: <SubscriptionFlow flow={flow} steps={flowSteps} />,
      children: [
        ...map(Object.values(StepGroups), (view) => ({
          element: <StepLayout view={view} />,
          children: map(filter(flowSteps, { group: view }), mapSubRoutes)
        })),
        {
          path: `/${flow}/*`,
          element: <Navigate replace to={`${firstRoute}${window.location.search}`} />
        }
      ]
    }
  ];
};

/**
 * ROUTER DEFINITION FOR THE SUBFLOW
 *
 * Contains all paths, including all existing flows and each step for those flows
 */
const router = createBrowserRouter([
  {
    element: <Providers />,
    errorElement: <ErrorBoundary />,
    children: [
      // Subflow routes
      {
        path: '/subscription/confirmation',
        loader: handleCheckoutConfirmation
      },
      {
        path: '/subscription/resume',
        loader: handleResumeSubFlow,
        element: <ResumeSubFlow />
      },
      ...mapFlowRoutes(Flows.Regular, regularSteps),
      ...mapFlowRoutes(Flows.FreshBaked, freshBakedSteps),
      // To handle showing subscription order confirmations
      {
        element: <OrderConfirmationLayout />,
        children: [
          {
            path: '/subscription/order-details',
            lazy: () => import('./subscription/order-details'),
            loader: loadOrderConfirmation
          }
        ]
      },
      // wildcard to redirect back to web app
      {
        path: '*',
        element: <RedirectWebApp />
      }
    ]
  }
]);

export const SubflowApp = () => (
  <GoogleReCaptchaProvider useEnterprise reCaptchaKey={NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </GoogleReCaptchaProvider>
);
